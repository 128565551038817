import http from '../../baseRequest'

/**
 * 查询管理员的用户名是否存在
 * @param {string} username 用户名
 */
export function queryUsername(username) {
    return http({
        method:'get',
        params:{username},
        url:'/common/admin/queryUsername'
    })
}


