<template>
  <div>小程序管理系统</div>
</template>

<script>
import { getTotalAdminCount, loginByPassword } from '../api'
import { queryUsername } from '../api/common/admin'

export default {
    mounted() {
    }
}
</script>

<style>

</style>